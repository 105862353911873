(function (window) {
  try {
    new DragEvent('test');
    return false; // No need to polyfill
  } catch (e) {
    // Need to polyfill - fall through
  }

  var DragEvent = MouseEvent;

  window.DragEvent = DragEvent;
})(window);